import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as CalculatorActions from './calculator.actions';
import { CalculatorService } from '../services/calculator-service';
import { FASAutoCalculatorResponse } from '../models/estimated-pension.data';


@Injectable()
export class CalculatorEffects {

  estimatePension$ = createEffect(() => {
    return this.actions$.pipe( 
      ofType(CalculatorActions.estimatePension),
      mergeMap(action => {
        return this.calculatorService.estimatePension(action.values).pipe(
          map(estimate => CalculatorActions.estimatePensionSuccess({ data: estimate })),
          catchError(error => of(CalculatorActions.estimatePensionFailure({ error })))
        );
      })
    );
  });

  runFASCalculation$ = createEffect(() => {
   return this.actions$.pipe(
      ofType(CalculatorActions.getFasValueAutoCal),
      mergeMap(({ body }) => {
        return this.calculatorService.getFasAutoCal(body).pipe(
          map((fasAutoCal: FASAutoCalculatorResponse) => {
            return CalculatorActions.getFasValueAutoCalSuccess({ fasAutoCal });
          }),
          catchError((err) => {
            return of(CalculatorActions.getFasValueAutoCalFailure({ errorMsg: err.message }));
          })
        );
      })
    )
  });

  constructor(private actions$: Actions, private calculatorService: CalculatorService) { }
}