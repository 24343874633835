import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MonoTypeOperatorFunction, Observable } from "rxjs";
import { retry, retryWhen, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { EstimatedPension, FASAutoCalculatorRequest, FASAutoCalculatorResponse } from "../models/estimated-pension.data";
import { timedRetryStrategy } from "./timedRetryStrategy";

@Injectable({
    providedIn: 'root'
})
export class CalculatorService {
    retryStrategy:MonoTypeOperatorFunction<any> = retryWhen(timedRetryStrategy({
        timeoutDuration: environment.http.timeout,
        maxRetryAttempts: environment.http.retry,
        scalingDuration: 500
      }));

    constructor(private httpClient: HttpClient) { }

    public estimatePension(values: any): Observable<EstimatedPension> {        
        return this.httpClient.post<EstimatedPension>(`${environment.apiUrl}/Calculators/PensionEstimator?t=${Math.random()}`, values).pipe(this.retryStrategy);
    }

    public getFasAutoCal(data: FASAutoCalculatorRequest): Observable<FASAutoCalculatorResponse> {
        let body = {
          ...data
        }
        return this.httpClient.post<FASAutoCalculatorResponse>(`${environment.apiUrl}/Calculators/fas/calculate`, body);
    }
}