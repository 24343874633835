<div class="mainContent">
  <!-- <div class="row">
    <h1>{{ page.header }}</h1>
  </div> -->

  <!-- <form>
    <ng-container *ngFor="let formInput of formInputs">
      <ng-container [ngSwitch]="formInput.key">
        <psrs-calc-list-field
          *ngSwitchCase="TEMPLATE_KEY.Gender"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [listOptions]="formInput.listItems"
          [helpText]="formInput.helpText"
          [defaultValue]="submissionValues[TEMPLATE_KEY.Gender]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
        ></psrs-calc-list-field>

        <psrs-calc-date-field
          *ngSwitchCase="TEMPLATE_KEY.Birthday"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [onlyPastDates]="'true'"
          [comesBeforeDate]="submissionValues[TEMPLATE_KEY.StartDate]"
          [defaultValue]="submissionValues[TEMPLATE_KEY.Birthday]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
          [isDisabled]="isDisabled"
          #birthdayComponentRef
        ></psrs-calc-date-field>

        <psrs-calc-list-field
          *ngSwitchCase="TEMPLATE_KEY.Tier"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [listOptions]="formInput.listItems"
          [helpText]="formInput.helpText"
          [defaultValue]="submissionValues[TEMPLATE_KEY.Tier]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
        ></psrs-calc-list-field>

        <psrs-calc-date-field
          *ngSwitchCase="TEMPLATE_KEY.StartDate"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [onlyPastDates]="'true'"
          [comesAfterDate]="submissionValues[TEMPLATE_KEY.Birthday]"
          [comesBeforeDate]="submissionValues[TEMPLATE_KEY.EndDate]"
          [defaultValue]="submissionValues[TEMPLATE_KEY.StartDate]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
          #startDateComponentRef
        ></psrs-calc-date-field>

        <psrs-calc-date-field
          *ngSwitchCase="TEMPLATE_KEY.EndDate"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [onlyFutureDates]="'true'"
          [comesAfterDate]="submissionValues[TEMPLATE_KEY.StartDate]"
          [defaultValue]="submissionValues[TEMPLATE_KEY.EndDate]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
          [isDisabled]="isDisabled"
        ></psrs-calc-date-field>

        <psrs-calc-currency-field
          *ngSwitchCase="TEMPLATE_KEY.Salary"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [defaultValue]="submissionValues[TEMPLATE_KEY.Salary]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
          [isDisabled]="isDisabled"
        ></psrs-calc-currency-field>

        <psrs-calc-time-period-field
          *ngSwitchCase="TEMPLATE_KEY.LeaveTime"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [granulation]="formInput.granulation"
          [defaultValue]="submissionValues[TEMPLATE_KEY.LeaveTime]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
        ></psrs-calc-time-period-field>

        <psrs-calc-time-period-field
          *ngSwitchCase="TEMPLATE_KEY.YearsOfService"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [granulation]="formInput.granulation"
          [isDisabled]="disableYOS"
          [overwriteYOS]="overwriteYOS"
          [defaultValue]="submissionValues[TEMPLATE_KEY.YearsOfService]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
        ></psrs-calc-time-period-field>

        <psrs-calc-conditional-age-field
          *ngSwitchCase="TEMPLATE_KEY.IncludeBeneficiary"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [useGender]="formInput.includeGender"
          [defaultValue]="submissionValues[TEMPLATE_KEY.IncludeBeneficiary]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
        ></psrs-calc-conditional-age-field>

      </ng-container>
    </ng-container>
    <div class="row no-gutters msg">
      <div class="col error offset-1">
        <small>{{ errorMessage }}</small>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col">
        <button
          *ngIf="hasValidationErrors"
          type="button"
          class="btn btn-disabled btn-lg btn-block"
          disabled
          (click)="submitValues()"
        >
          View Options & Estimates
        </button>
        <button
          *ngIf="!hasValidationErrors"
          type="button"
          class="btn btn-calculator btn-lg btn-block"
          (click)="submitValues()"
        >
          View Options & Estimates
        </button>
      </div>
    </div>
  </form>
  
  <ng-template #disclaimerModal let-modal *ngIf="modalHtml" class="calc_modal">
    <div class="disc_laim">
    <div class="modal-header">
      <h4 class="modal-title discl">{{ page.header }} Disclaimer</h4>
    </div>
    <div class="modal-body disclaimer" [innerHtml]="modalHtml"></div>
    <div class="modal-footer my_ft">
      <button
        type="button"
        class="btn btn-modal btn-lg"
        (click)="acceptDisclaimer('Accepted Disclaimer')"
      >
        Accept
      </button>
      <button
        type="button"
        class="btn btn-modal btn-lg can"
        (click)="rejectDisclaimer('Rejected Disclaimer')"
      >
        Cancel
      </button>
    </div>
  </div>
  </ng-template>  -->
</div>

<div class="deskto_p">
  <div class="mob_pad">
    <h1>{{ page.header }}</h1>

    <div class="f_orms">
      <form>
        <div class="f_orms_row inputs">
          <ng-container *ngFor="let formInput of formInputs">
            <ng-container [ngSwitch]="formInput.key">
              <psrs-calc-list-field
                *ngSwitchCase="TEMPLATE_KEY.Gender"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [listOptions]="formInput.listItems"
                [helpText]="formInput.helpText"
                [defaultValue]="submissionValues[TEMPLATE_KEY.Gender]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
                className="common_input"
              ></psrs-calc-list-field>

              <psrs-calc-date-field
                *ngSwitchCase="TEMPLATE_KEY.Birthday"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [helpText]="formInput.helpText"
                [onlyPastDates]="'true'"
                [comesBeforeDate]="submissionValues[TEMPLATE_KEY.StartDate]"
                [defaultValue]="submissionValues[TEMPLATE_KEY.Birthday]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
                [isDisabled]="isDisabled"
                #birthdayComponentRef
              ></psrs-calc-date-field>

              <psrs-calc-list-field
                *ngSwitchCase="TEMPLATE_KEY.Tier"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [listOptions]="formInput.listItems"
                [helpText]="formInput.helpText"
                [defaultValue]="submissionValues[TEMPLATE_KEY.Tier]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
              ></psrs-calc-list-field>

              <psrs-calc-date-field
                *ngSwitchCase="TEMPLATE_KEY.StartDate"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [helpText]="formInput.helpText"
                [onlyPastDates]="'true'"
                [comesAfterDate]="submissionValues[TEMPLATE_KEY.Birthday]"
                [comesBeforeDate]="submissionValues[TEMPLATE_KEY.EndDate]"
                [defaultValue]="submissionValues[TEMPLATE_KEY.StartDate]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
                #startDateComponentRef
              ></psrs-calc-date-field>

              <psrs-calc-date-field
                *ngSwitchCase="TEMPLATE_KEY.EndDate"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [helpText]="formInput.helpText"
                [onlyFutureDates]="'true'"
                [comesAfterDate]="submissionValues[TEMPLATE_KEY.StartDate]"
                [defaultValue]="submissionValues[TEMPLATE_KEY.EndDate]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
                [isDisabled]="isDisabled"
                #endDateComponentRef
              ></psrs-calc-date-field>

              <psrs-calc-currency-field
                *ngSwitchCase="TEMPLATE_KEY.Salary"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [helpText]="formInput.helpText"
                [defaultValue]="submissionValues[TEMPLATE_KEY.Salary]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
                [isDisabled]="isDisabled"
              ></psrs-calc-currency-field>

              <psrs-calc-time-period-field
                *ngSwitchCase="TEMPLATE_KEY.LeaveTime"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [helpText]="formInput.helpText"
                [granulation]="formInput.granulation"
                [defaultValue]="submissionValues[TEMPLATE_KEY.LeaveTime]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
              ></psrs-calc-time-period-field>

              <psrs-calc-time-period-field
                *ngSwitchCase="TEMPLATE_KEY.YearsOfService"
                [calcName]="page.header"
                [name]="formInput.name"
                [key]="formInput.key"
                [helpText]="formInput.helpText"
                [granulation]="formInput.granulation"
                [isDisabled]="disableYOS"
                [overwriteYOS]="overwriteYOS"
                [defaultValue]="submissionValues[TEMPLATE_KEY.YearsOfService]"
                (valueChanged)="updateSubmissionValues($event)"
                (statusChanged)="updateSubmissionStatus($event)"
              ></psrs-calc-time-period-field>

              
              <!-- <psrs-calc-date-field
              *ngSwitchCase="TEMPLATE_KEY.Birthday"
              [calcName]="page.header"
              [name]="formInput.name"
              [key]="formInput.key"
              [helpText]="formInput.helpText"
              [onlyPastDates]="'true'"
              [comesBeforeDate]="submissionValues[TEMPLATE_KEY.StartDate]"
              [defaultValue]="submissionValues[TEMPLATE_KEY.Birthday]"
              (valueChanged)="updateSubmissionValues($event)"
              (statusChanged)="updateSubmissionStatus($event)"
              [isDisabled]="isDisabled"
              #birthdayComponentRef
            ></psrs-calc-date-field>

            <psrs-calc-date-field
              *ngSwitchCase="TEMPLATE_KEY.StartDate"
              [calcName]="page.header"
              [name]="formInput.name"
              [key]="formInput.key"
              [helpText]="formInput.helpText"
              [onlyPastDates]="'true'"
              [comesAfterDate]="submissionValues[TEMPLATE_KEY.Birthday]"
              [comesBeforeDate]="submissionValues[TEMPLATE_KEY.EndDate]"
              [defaultValue]="submissionValues[TEMPLATE_KEY.StartDate]"
              (valueChanged)="updateSubmissionValues($event)"
              (statusChanged)="updateSubmissionStatus($event)"
              #startDateComponentRef
            ></psrs-calc-date-field>

            <psrs-calc-date-field
          *ngSwitchCase="TEMPLATE_KEY.EndDate"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [onlyFutureDates]="'true'"
          [comesAfterDate]="submissionValues[TEMPLATE_KEY.StartDate]"
          [defaultValue]="submissionValues[TEMPLATE_KEY.EndDate]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
          [isDisabled]="isDisabled"
        ></psrs-calc-date-field> -->
            </ng-container></ng-container
          >
        </div>
        <div class="f_orms_row pension_single_row inputs">
          <ng-container *ngFor="let formInput of formInputs">
            <ng-container [ngSwitch]="formInput.key">
              <psrs-calc-conditional-age-field
              *ngSwitchCase="TEMPLATE_KEY.IncludeBeneficiary"
              [calcName]="page.header"
              [name]="formInput.name"
              [key]="formInput.key"
              [helpText]="formInput.helpText"
              [useGender]="formInput.includeGender"
              [defaultValue]="
                submissionValues[TEMPLATE_KEY.IncludeBeneficiary]
              "
              (valueChanged)="updateSubmissionValues($event)"
              (statusChanged)="updateSubmissionStatus($event)"
            ></psrs-calc-conditional-age-field>
              </ng-container>
              </ng-container>
              </div>
              <div class="row no-gutters set_msg msg">
                <div class="col error">
                  <small>{{ errorMessage }}</small>
                </div>
              </div>
        <!-- <div class="f_orms_row inputs sal">
        <ng-container *ngFor="let formInput of formInputs">
          <ng-container [ngSwitch]="formInput.key">
            <psrs-calc-currency-field
          *ngSwitchCase="TEMPLATE_KEY.Salary"
          [calcName]="page.header"
          [name]="formInput.name"
          [key]="formInput.key"
          [helpText]="formInput.helpText"
          [defaultValue]="submissionValues[TEMPLATE_KEY.Salary]"
          (valueChanged)="updateSubmissionValues($event)"
          (statusChanged)="updateSubmissionStatus($event)"
          [isDisabled]="isDisabled"
        ></psrs-calc-currency-field>

        <psrs-calc-time-period-field
        *ngSwitchCase="TEMPLATE_KEY.YearsOfService"
        [calcName]="page.header"
        [name]="formInput.name"
        [key]="formInput.key"
        [helpText]="formInput.helpText"
        [granulation]="formInput.granulation"
        [isDisabled]="disableYOS"
        [overwriteYOS]="overwriteYOS"
        [defaultValue]="submissionValues[TEMPLATE_KEY.YearsOfService]"
        (valueChanged)="updateSubmissionValues($event)"
        (statusChanged)="updateSubmissionStatus($event)"
      ></psrs-calc-time-period-field>
            
            </ng-container
        ></ng-container>
      </div> -->
        <!-- <div class="f_orms_row inputs full_input">
        <ng-container *ngFor="let formInput of formInputs">
          <ng-container [ngSwitch]="formInput.key">
            <psrs-calc-conditional-age-field
            *ngSwitchCase="TEMPLATE_KEY.IncludeBeneficiary"
            [calcName]="page.header"
            [name]="formInput.name"
            [key]="formInput.key"
            [helpText]="formInput.helpText"
            [useGender]="formInput.includeGender"
            [defaultValue]="submissionValues[TEMPLATE_KEY.IncludeBeneficiary]"
            (valueChanged)="updateSubmissionValues($event)"
            (statusChanged)="updateSubmissionStatus($event)"
          ></psrs-calc-conditional-age-field>
            </ng-container
        ></ng-container>
      </div> -->
        <div class="row no-gutters esti">
          <div class="col">
            <!-- <button
              *ngIf="hasValidationErrors"
              type="button"
              class="btn btn-disabled btn-lg btn-block"
              disabled
              (click)="submitValues()"
            >
              View Estimates
            </button>
            <button
              *ngIf="!hasValidationErrors"
              type="button"
              class="btn btn-calculator btn-lg btn-block"
              (click)="submitValues()"
            >
              View Estimates
            </button> -->
            <button
              *ngIf="hasValidationErrors"
              type="button"
              class="btn btn-disabled btn-lg btn-block bl_btn btn-disable"
              disabled
            >
              View Estimates
            </button>
            <button
              *ngIf="!hasValidationErrors"
              type="button"
              class="btn btn-calculator btn-lg btn-block bl_btn"
              (click)="submitValues()"
            >
              View Estimates
            </button>
          </div>
        </div>
      </form>
    </div>
    <ng-template
      #disclaimerModal
      let-modal
      *ngIf="modalHtml"
      class="calc_modal"
    >
      <div class="disc_laim">
        <div class="modal-header ret">
          <h4 class="modal-title discl">{{ page.header }} Disclaimer</h4>
        </div>
        <div
          class="modal-body disclaimer dis_claim_scroll"
          [innerHtml]="modalHtml"
        ></div>
        <div class="modal-footer my_ft">
          <button
            type="button"
            class="btn btn-modal btn-lg"
            (click)="acceptDisclaimer('Accepted Disclaimer')"
          >
            Accept
          </button>
          <button
            type="button"
            class="btn btn-modal btn-lg can"
            (click)="rejectDisclaimer('Rejected Disclaimer')"
          >
            Cancel
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
