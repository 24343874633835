import { createAction, props } from '@ngrx/store';
import { EstimatedPension,  FASAutoCalculatorRequest, FASAutoCalculatorResponse } from '../models/estimated-pension.data';

export const estimatePension = createAction('[Calculator View] Estimate Pension', props<{ values: any }>());
export const estimatePensionSuccess = createAction('[Calculator View] Estimate Pension Success', props<{ data: EstimatedPension }>());
export const estimatePensionFailure = createAction('[Calculator View] Estimate Pension Failure', props<{ error: any }>());

// FAS Auto Feature
export const FASAutoCalculationRequest = '[FASAutoCal/API] Send Request';
export const FASAutoCalculationFailure = '[FASAutoCal/API] Failure';
export const FASAutoCalculationSuccess = '[FASAutoCal/API] Success';

// FAS Auto Feature
export const getFasValueAutoCal = createAction(
    FASAutoCalculationRequest,
    props<{ body: FASAutoCalculatorRequest }>()
  );
  export const getFasValueAutoCalSuccess = createAction(
    FASAutoCalculationSuccess,
    props<{ fasAutoCal: FASAutoCalculatorResponse }>()
  );
  export const getFasValueAutoCalFailure = createAction(
    FASAutoCalculationFailure,
    props<{ errorMsg: string }>()
  );