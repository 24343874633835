import { createReducer, on } from '@ngrx/store';
import * as CalculatorActions from './calculator.actions';
import { CalculatorState } from './calculator.state';

export const calculatorFeatureKey = 'calculator';

export const coreReducer = createReducer<CalculatorState>({ views: { }, data: { } },
  on(CalculatorActions.estimatePension, (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        estimatedPension: null
      },
      views: {
        ...state.views,
        pensionEstimator: action.values
      }
    }
  }),
  on(CalculatorActions.estimatePensionSuccess, (state, action) => {
    var nrd = new Date(action.data.retirementDate);
    return {
      ...state,
      data: {
        ...state.data,
        estimatedPension: {
          ...action.data,
          retirementDate: nrd
        }
      }
    }
  }),
  on(CalculatorActions.estimatePensionFailure, (state, action) => {
    return {
      error: {
        ...action.error        
      }
    }
  }),
  on(CalculatorActions.getFasValueAutoCal, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CalculatorActions.getFasValueAutoCalSuccess, (state, { fasAutoCal }) => ({
    ...state,
    isLoading: false,
    fasAutoCal: fasAutoCal
  })),
  on(CalculatorActions.getFasValueAutoCalFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    fasAutoCal: undefined,
  }))
);